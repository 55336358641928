







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';

@Component({})
export default class GenproxBadge extends Vue {
  @Prop({ default: 'gn-primary' }) variant: string;
  @Prop({ default: false }) outline: boolean;
  @Prop({ default: false }) circle: boolean;
  @Prop() text: string;
  @Prop() icon: object;
}
