var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: ['gn-badge', _vm.variant || '', _vm.outline ? 'outlined' : '', _vm.icon ? 'has-icon' : '', _vm.circle ? 'is-circle' : '']
  }, [_c('span', {
    staticClass: "inline-block"
  }, [_vm._v(_vm._s(_vm.text))]), _vm.icon ? _c(_vm.icon, {
    tag: "component",
    staticClass: "ml-1 h-4 w-4"
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }